import './App.css';
import Karuselli from './Karuselli';
import Navigaatio from './Navigaatio';
import mypic from './me.jpg';
import ithekuma from './it-hekuma_2023.jpg';
import * as dt from 'datetimejs';
import Cv from './Cv';
import { useState } from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll'
import GetStats from './StravaClient';

function App() {
  let diff = dt.dtdelta.delta(new Date(1997,6,20), new Date());
  let married = dt.dtdelta.delta(new Date(2020,8,25),new Date());
  let kid1 = dt.dtdelta.delta(new Date(2021,0,14),new Date());
  let kid2 = dt.dtdelta.delta(new Date(2022,2,1),new Date());
  let kid3 = dt.dtdelta.delta(new Date(2024,6,28),new Date());
  const [token, setToken] = useState(sessionStorage.getItem("token") ?? "");
  return (
      <div className="App">
        <Navigaatio></Navigaatio>
        <Karuselli></Karuselli>
        <section>
          <img id="mypic" alt="Me" src={mypic} ></img>
          <h2>Who? - I tell now..</h2>
          <p>I'm <b>{(diff.hours / 8765.82).toPrecision(3)} years old</b> Senior Software Developer from Finland.</p>
          <p>I'm self-taught, i like learn new techs and improve myself.</p>
          { 1+1 === 3 &&<Cv setToken={setToken} auth={token !== ""} />}
          <h2>What i do now?</h2>
          <img id="hekuma" alt="Me in IT-Hekuma 2023" src={ithekuma}></img>
          <p>I work for Visma and i'm really happy with it.</p>
          <p>I'm doing few own projects.</p>
          <ul>
            <li>Game project with friends</li>
          </ul>
          <h2>Why i like programming?</h2>
          <p>Simple, i like challenge myself and make software for people.</p>
          <h2>Programming languages </h2>
          <ul>
            <AnimationOnScroll animateIn="animate__fadeIn">
            <li>C#</li>
            <li>Javascript</li>
            <li>Java</li>
            <li>PHP</li>
            <li>Python</li>
            </AnimationOnScroll>
          </ul>
          <h2>Other</h2>
          <ul>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <li>Docker</li>
            <li>Snapcraft</li>
            <li>Linux</li>
            <li>Servers</li>
            <li>Cyber security</li>
            </AnimationOnScroll>
          </ul>
          <h2>My hobbies</h2>
          <ul>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <li>Programming</li>
            <li>E-biking</li>
          </AnimationOnScroll>
          </ul>
          <GetStats></GetStats>
          <h2>Family</h2>
          <AnimationOnScroll animateIn="animate__fadeIn">
          <p>❤️ Katariina Mariapori (Married {(married.hours / 8765.82).toPrecision(3)} years)</p>
          <p>❤️ Oliver Mariapori ({(kid1.hours / 8765.82).toPrecision(3)} years old)</p>
          <p>❤️ Aurora Mariapori ({(kid2.hours / 8765.82).toPrecision(3)} years old)</p>
          <p>❤️ Sofia Mariapori ({(kid3.hours / 8765.82).toPrecision(3)} years old)</p>
          </AnimationOnScroll>
        </section>
        <footer>
          © Topias Mariapori, 2024 | Made with React
        </footer>
      </div>
  );
}
export default App;
