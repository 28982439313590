import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import slide1 from './ekaslaidi.jpg';
import slide2 from './dad.jpg';
import heart from './heart.png';
import topias from './whoaretopias.jpg'
function Karuselli() {
    return (
<Carousel fade variant='light' className='kustomkaruselli'>
<Carousel.Item>
<img
      className="d-block w-100"
      src={topias}
      alt="Who are Topias?"
    />
    <Carousel.Caption>
      <h3>Who are Topias Mariapori?</h3>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide1}
      alt="Software Developer"
    />
    <Carousel.Caption>
      <h3>Software Developer</h3>
      <p>I <img id="sydan" src={heart} alt="love"></img> Programming</p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={slide2}
      alt="Dad"
    />
    <Carousel.Caption>
      <h3>Dad</h3>
      <p>Dad of three</p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>
    );
  }
  
  export default Karuselli;
  