import React, { useState, useEffect } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

function GetStats() {
    const [bikingStats, setBikingStats] = useState(null);

    useEffect(() => {
        async function fetchStats() {
            try {
                const response = await fetch('https://api.mariapori.fi/GetStats');
                const data = await response.json();
                setBikingStats(data);
            } catch (error) {
                console.error('Error fetching biking stats:', error);
            }
        }
        fetchStats();
    }, []);

    if (!bikingStats) {
        return <p>Loading biking stats...</p>;
    }

    return (
        <>
            <h2>E-biking</h2>
            <AnimationOnScroll animateIn="animate__fadeIn">
                <p>All-time distance: {(bikingStats.all_ride_totals.distance / 1000).toPrecision(4)} km</p>
                <p>All-time biking time: {(bikingStats.all_ride_totals.moving_time / 60 / 60).toPrecision(2)} hours</p>
                <p>This year distance: {(bikingStats.ytd_ride_totals.distance / 1000).toPrecision(4)} km</p>
                <p>This year biking time: {(bikingStats.ytd_ride_totals.moving_time / 60 / 60).toPrecision(2)} hours</p>
                <p>Last 4 weeks distance: {(bikingStats.recent_ride_totals.distance / 1000).toPrecision(4)} km</p>
                <p>Last 4 weeks biking time: {(bikingStats.recent_ride_totals.moving_time / 60 / 60).toPrecision(2)} hours</p>
            </AnimationOnScroll>
        </>
    );
}

export default GetStats;
