import { Container, Nav, Navbar } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
function Navigaatio() {
    return (
<Navbar bg="dark" variant="dark" expand="lg">
  <Container>
    <Navbar.Brand href="/">Mariapori.fi</Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="https://github.com/Mariapori" target="_blank">Github</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    );
  }
  
  export default Navigaatio;
  